import { inject, observer } from 'mobx-react';
import React from 'react';
import { BiInfoCircle } from 'react-icons/bi';
// import { BsFillCheckCircleFill } from 'react-icons/bs';
import { IMaskInput } from 'react-imask';
import { Popup } from 'semantic-ui-react';

import { validators } from '../../utils/input-tools';

class RoutingInput extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: '',
      name: '',
      component: '',
      error: false,
      errorText: '',
      isSuccess: false,
      isNewScreen: true,
      mouseIn: false,
      iconLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.builderInput = this.builderInput.bind(this);
    this.focus = this.focus.bind(this);
    this.mouseIn = this.mouseIn.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
  }

  mouseIn(): void {
    this.setState({
      mouseIn: true,
    });
  }

  mouseOut(): void {
    this.setState({
      mouseIn: false,
    });
  }

  focus(e: any): void {
    e.target.focus();
    e.target.setSelectionRange(0, 1000000000);
  }

  componentDidMount(): void {
    const error = this.props.iRequired && validators.isEmpty(this.props.iValue);
    const region = this.props.boarding?.services?.card?.processing_region;
    this.setState(
      {
        component: this.props.iComponent,
        value: this.props.iValue,
        name: this.props.iStoreName,
        errorText: '',
        isSuccess: false,
      },
      (): void => {
        this.props.boarding[this.props.iComponent].setProp(
          this.state.name,
          this.state.value,
          this.props.iMetadata
        );
        this.props.boarding[this.props.iComponent].setError(
          this.state.name,
          this.state.error,
          this.props.iMetadata
        );
      }
    );
    if (this.props.iValue) {
      this.setState({
        iconLoading: true,
        errorText: '',
        isSuccess: false,
      });
      if (region?.toLowerCase() !== 'ca') {
        const { accountNumber } =
          this.props.boarding[this.props.iComponent].accountsInfo[
            this.props.iMetadata.typeBank
          ];
        if (!accountNumber) {
          this.setState({
            iconLoading: false,
            error: false,
            errorText: '',
            isSuccess: false,
          });
          return;
        }
        this.setState({
          error: error,
          errorText: '',
          isSuccess: true,
          iconLoading: false,
        });
      } else {
        this.setState({
          error: false,
          errorText: '',
          isSuccess: true,
          iconLoading: false,
        });
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<any>): void {
    if (prevProps.iValue !== this.props.iValue) {
      this.setState({ value: this.props.iValue }, () => {
        const { accountNumber } =
          this.props.boarding[this.props.iComponent].accountsInfo[
            this.props.iMetadata.typeBank
          ];
        if (this.props.iValue.length > 4 && accountNumber) this.handleBlur();
      });
    }
  }

  handleChange(name: string, event: any, value: any): void {
    const region = this.props.boarding?.services?.card?.processing_region;
    const validationsByRegion = validators.stringValidator(
      'routingCA',
      value.value
    );
    const error =
      validators.isEmpty(value.value) || region?.toLowerCase() !== 'ca'
        ? value.value.length > 0 && value.value.length < 9
        : validationsByRegion;
    this.setState(
      {
        value: value.value,
        isNewScreen: false,
        errorText: '',
        isSuccess: false,
        error,
      },
      (): void => {
        const field = document.getElementsByClassName(this.props.iName)[0];
        if (this.state.value) {
          field.classList.add('field--not-empty');
        } else {
          field.classList.remove('field--not-empty');
        }
        this.props.boarding[this.props.iComponent].setProp(
          name,
          this.state.value,
          this.props.iMetadata
        );
        this.props.boarding[this.props.iComponent].setError(
          name,
          this.state.error,
          this.props.iMetadata
        );
      }
    );
  }

  handleBlur(): void {
    this.setState({ iconLoading: true, errorText: '', isSuccess: false });
    this.setState({
      error: false,
      errorText: '',
      isSuccess: true,
      iconLoading: false,
    });
  }

  builderInput(): React.ReactElement {
    const region = this.props.boarding?.services?.card?.processing_region;
    const error =
      this.props.boarding[this.props.iComponent].getError(
        this.props.iStoreName,
        this.props.iMetadata
      ) === true;
    const { deposit, withdrawal } = this.props.banking;
    const { nextClicked } = this.props.boarding;
    const validationError =
      this.props.iMetadata.index === 'deposit'
        ? deposit.getError()
        : withdrawal.getError();
    return (
      <div
        className={
          (error && !this.state.isNewScreen) ||
          (this.state.error && nextClicked)
            ? 'ui fluid input error loading'
            : 'ui fluid input loading'
        }
      >
        {(this.props.iToolTip || this.state.errorText !== '') &&
          validationError && (
            <Popup
              trigger={
                <BiInfoCircle
                  color="red"
                  className="info-icon masked-in-input"
                />
              }
            >
              {this.state.errorText !== ''
                ? this.state.errorText
                : this.props.iToolTip}
            </Popup>
          )}
        {/* {(this.state.errorText === '' && this.state.isSuccess) || !validationError && (
          <BsFillCheckCircleFill
            color="limegreen"
            className="info-icon masked-in-input"
          />
        )} */}
        <IMaskInput
          mask={region?.toLowerCase() !== 'ca' ? '000000000' : '00000000'}
          name={this.props.iName}
          value={this.state.value ? String(this.state.value) : ''}
          autoComplete="off"
          defaultValue=""
          type={this.props.iType ? this.props.iType : 'text'}
          unmask={true}
          onAccept={(event: any, value: any): void =>
            this.handleChange(this.props.iStoreName, event, value)
          }
          placeholder={
            this.props.iTitle + (this.props.iRequired === true ? ' *' : '')
          }
          className="field__input"
          readOnly={this.props.iReadonly}
          id={this.props.iName}
          onMouseEnter={this.mouseIn}
          onMouseLeave={this.mouseOut}
        />
        {this.state.iconLoading && (
          <div className="icon-container">
            <i className="loader"></i>
          </div>
        )}
      </div>
    );
  }

  render(): React.ReactNode {
    return (
      <>
        <div className={`field ${this.props.iName}`}>
          <label className="field__label" htmlFor={this.props.iName}>
            {this.props.iTitle} {this.props.iRequired === true ? ' *' : ''}
          </label>
          {this.builderInput()}
        </div>
      </>
    );
  }
}

export default inject('boarding', 'banking')(observer(RoutingInput));
