/* eslint-disable no-debugger */
import 'react-toastify/dist/ReactToastify.css';

import * as Sentry from '@sentry/react';
import HelloSign from 'hellosign-embedded';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  BiCheckCircle,
  BiChevronLeft,
  BiChevronRight,
  BiCog,
  BiDetail,
  BiDollarCircle,
  BiEnvelope,
  BiUserCheck,
  BiX,
} from 'react-icons/bi';
import { RiBankLine } from 'react-icons/ri';
import Lottie from 'react-lottie';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import type { SemanticCOLORS } from 'semantic-ui-react';
import {
  Button,
  Container,
  Divider,
  Grid,
  Icon,
  Image,
  Input,
  Message,
  MessageHeader,
  Modal,
  Popup,
  Progress,
  Tab,
} from 'semantic-ui-react';

import payabli from '../assets/images/payabli.png';
import Banking from '../components/Banking';
import BusinessDetail from '../components/BusinessDetail';
import ErrorPopUp from '../components/ErrorPopUp';
import CopyInfoInput from '../components/inputs/CopyInfoInput';
import LoginBoarding from '../components/LoginBoarding';
import OwnerContact from '../components/OwnerContact';
import Ownership from '../components/Ownership';
import Pricing from '../components/Pricing';
import Processing from '../components/Processing';
import Signer from '../components/Signer';
import TermsConditions from '../components/TermsConditions';
import { STATUS } from '../interfaces/IAttachment';
import { OWNER_STATUS } from '../interfaces/IOwner';
import CheckApplication from '../lotties/CheckApplication.json';
import LoadingApplication from '../lotties/LoadingSquaredApplication.json';
import { validators } from '../utils/input-tools';
import { MESSAGE } from '../utils/messageResponse';
import { middlewareApi } from '../utils/middleware';

class BoardingLinkView extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentPos: 0,
      currentSection: '',
      validTabs: [],
      activeIndex: 0,
      activePos: [],
      loading: false,
      appemail: null,
      modalEmailError: false,
      appId: null,
      logo: null,
      recipientEmailNotification: true,
      resumable: true,
      update: false,
      tid: '0',
      orgId: '0',
      linkId: '0',
      loadingText: 'Loading...',
      saveEmailModalIsOpen: false,
      middlewareResult: {},
      endLinkModalIsOpen: false,
      saveLinkModalIsOpen: false,
      login: false,
      referenceId: null,
      reloadError: false,
      zipFile: '',
      editMode: false,
      boardingMode: -99,
      boardingConfiguration: {},
      config: {} as any,
      noLoginEdit: false,
      listColors: {
        '#DB2828': 'red',
        '#F2711C': 'orange',
        '#FBBD08': 'yellow',
        '#B5CC18': 'olive',
        '#21BA45': 'green',
        '#00B5AD': 'teal',
        '#2185D0': 'blue',
        '#6435C9': 'violet',
        '#A333C8': 'purple',
        '#E03997': 'pink',
        '#A5673F': 'brown',
        '#767676': 'grey',
        '#1B1C1D': 'black',
      },
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.gotoPrevTab = this.gotoPrevTab.bind(this);
    this.gotoNextTab = this.gotoNextTab.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.saveToCompleteLater = this.saveToCompleteLater.bind(this);
    this.getApplicationFromApi = this.getApplicationFromApi.bind(this);
    this.generatePDF = this.generatePDF.bind(this);
    this.gotoNextTabAndSubmit = this.gotoNextTabAndSubmit.bind(this);
    this.renderBoarding = this.renderBoarding.bind(this);
  }

  onCopyOwnerInfo(e: any, contactId: number): void {
    this.props.boarding.onCopyOwnerInfo(contactId);
  }

  /**
   * A function that is called when the tab is changed.
   * @param {any} e - any - the event object
   * @param {any}  - any - this is the type of the parameter. In this case, it's any.
   */
  handleTabChange(e: any, { activeIndex }: any): void {
    this.gotoNextTab(activeIndex);
  }

  /**
   * It takes an event and a value, and then sets the state of the component to the value of the event
   * @param {any} e - any - the event object
   * @param {any} value - the value of the input field
   */
  handleChangeEmail(e: any, value: any): void {
    let error = false;
    if (validators.stringValidator('email', value.value)) {
      error = true;
    }
    this.setState({ appemail: value.value, modalEmailError: error });
  }

  async getApplicationFromApi(email: any, referenceId: any): Promise<void> {
    const self = this;
    this.setState({
      loading: true,
      loadingText: 'Loading Application Link...',
      login: false,
    });
    try {
      const data = await this.props.boarding.boardingService.read(
        process.env.REACT_APP_TOKEN,
        email,
        referenceId,
        this.props.match.params.id
      );
      this.setState(
        {
          editMode: true,
          boardingMode: this.props.mode,
          tid: data.data.templateId,
          linkId: data.data.boardingLinkId,
          orgId: data.data.orgId,
          update: true,
          appId: data.data.idApplication,
        },
        () => {
          this.setBuilderData(data);
        }
      );
    } catch (error: any) {
      const errorMessage =
        error.response && error.response.data.responseText
          ? error.response.data.responseText
          : error.message
          ? error.message
          : 'Something is Wrong!';
      self.setState(
        {
          error: errorMessage,
          loading: false,
          login: true,
        },
        () => {
          Sentry.captureEvent(new Error(error));
        }
      );
    }
  }

  checkBuilderData(data: any): boolean {
    const allElements = Object.entries(data || []).map((e) => e[1]);
    return allElements.every((r) => r === null);
  }

  setBuilderData(data: any): void {
    const boardingData = middlewareApi(data.data);
    if (this.checkBuilderData(data.data.builderData)) {
      const errorMessage =
        'This application has the builderData with problems. Please contact us.';
      this.setState({ error: errorMessage, loading: false, login: true });
      return;
    }
    const ownerData = boardingData.owners;
    const signerData = boardingData.signer;
    const contactData = boardingData.contacts;
    const { zipfile, filelist } =
      boardingData.attachments !== undefined
        ? boardingData.attachments
        : { zipfile: '', filelist: [] };
    this.props.boarding.setMultiOwner(boardingData?.multipleOwners);
    this.props.boarding.setMultiContact(boardingData?.multipleContacts);
    this.props.boarding.setMinDocument(boardingData?.minimumDocuments);
    this.props.boarding.setUploadDocuments(
      data.data?.builderData?.banking?.uploadDocuments
    );
    this.props.boarding.services.setCardRegion(
      boardingData?.services?.card?.processing_region?.value
    );
    this.props.boarding.setValidOwner(
      !/Non-Profit Org|Government/.test(
        boardingData.businessDetails.btype.value
      )
    );

    filelist.map((fileElement: any) => {
      this.props.boarding.attachments.addAttachment(
        fileElement?.originalName?.split('.').at(-1),
        fileElement?.originalName,
        fileElement?.descriptor,
        '',
        '',
        0,
        STATUS.pushed,
        fileElement?.zipName
      );
    });
    if (!filelist.length) {
      this.props.boarding.attachments.addAttachment();
    }
    this.props.boarding.signer.setProp('name', signerData.name?.value);
    this.props.boarding.signer.setProp('ssn', signerData.ssn?.value);
    this.props.boarding.signer.setProp('dob', signerData.dob?.value);
    this.props.boarding.signer.setProp('phone', signerData.phone?.value);
    this.props.boarding.signer.setProp('email', signerData.email?.value);
    this.props.boarding.signer.setProp('address', signerData.address?.value);
    this.props.boarding.signer.setProp('address1', signerData.address1?.value);
    this.props.boarding.signer.setProp('state', signerData.state?.value);
    this.props.boarding.signer.setProp('country', signerData.country?.value);
    this.props.boarding.signer.setProp('city', signerData.city?.value);
    this.props.boarding.signer.setProp('zip', signerData.zip?.value);
    if (Object.keys(contactData).length) {
      contactData.map((contact: any) => {
        this.props.boarding.contacts.addContact(
          contact.contactName?.value,
          contact.contactEmail?.value,
          contact.contactTitle?.value,
          contact.contactPhone?.value,
          OWNER_STATUS.posted
        );
      });
    }
    if (Object.keys(ownerData).length) {
      ownerData.map((owner: any) => {
        this.props.boarding.owners.addOwner(
          owner.ownername?.value,
          owner.ownertitle?.value,
          owner.ownerpercent?.value,
          owner.ownerssn?.value,
          owner.ownerdob?.value,
          owner.ownerphone1?.value,
          owner.ownerphone2?.value,
          owner.owneremail?.value,
          owner.ownerdriver?.value,
          owner.odriverstate?.value,
          owner.oaddress?.value,
          owner.oaddress1?.value,
          owner.ostate?.value,
          owner.ocountry?.value,
          owner.ocity?.value,
          owner.ozip?.value,
          OWNER_STATUS.posted
        );
      });
    }

    const businessData = boardingData.businessDetails;
    const hideOwners = businessData.btype?.value
      ? !/Non-Profit Org|Government/.test(businessData.btype.value)
      : Array.isArray(ownerData)
      ? ownerData.find((owner: any) =>
          JSON.stringify(owner).includes('"visible":true')
        ) !== undefined
      : true;
    const contactFiles = boardingData.contacts;
    const hideContactData = Array.isArray(contactFiles)
      ? contactFiles.find((contact: any) =>
          JSON.stringify(contact).includes('"visible":true')
        ) !== undefined
      : true;

    let hideOwnerContacts = false;
    if (
      Array.isArray(ownerData) &&
      Array.isArray(contactFiles) &&
      this.isHideTab(ownerData[0]) &&
      this.isHideTab(contactFiles[0])
    ) {
      hideOwnerContacts = true;
    }

    const processingData = boardingData.processing;
    const serviceData = boardingData.services;
    const hideService =
      Object.entries(serviceData || [])
        .map((element) => element[1])
        .filter(
          (it: any) => it?.visible && (it.price !== null || it.fees !== null)
        ).length === 0;
    const hideServiceData = !!this.hideServicesData(serviceData);

    this.setState(
      {
        loading: true,
        middlewareResult: boardingData,
        tid: data.data.referenceTemplateId,
        orgId: data.data.orgId,
        linkId: data.data.id,
        zipFile: zipfile,
        logo: data.data.logo || null,
        resumable: data.data.resumable,
        recipientEmailNotification: data.data.recipientEmailNotification,
        boardingConfiguration: data.data.properties,
      },
      () => {
        const atabs = [];
        let cs = '';
        if (
          boardingData?.businessDetail !== null &&
          !this.isHideTab(businessData)
        ) {
          atabs.push(0);
          cs = 'business';
        }
        if (
          boardingData?.owners !== null &&
          (hideOwners || hideContactData) &&
          !hideOwnerContacts
        ) {
          atabs.push(1);
          if (cs === '') {
            cs = 'owners';
          }
        }
        if (
          boardingData?.processing !== null &&
          !this.isHideTab(processingData)
        ) {
          atabs.push(2);
          if (cs === '') {
            cs = 'processing';
          }
        }
        if (
          boardingData?.services !== null &&
          !hideService &&
          !hideServiceData
        ) {
          atabs.push(3);
          if (cs === '') {
            cs = 'services';
          }
        }
        if (boardingData?.banking !== null) {
          atabs.push(4);
          if (cs === '') {
            cs = 'banking';
          }
        }
        atabs.push(5);
        this.setState(
          {
            currentSection: cs,
            activePos: atabs,
          },
          () => {
            this.navigateAllTabs();
          }
        );
      }
    );
  }

  /**
   * A lifecycle method that is called when the component is mounted. It is used to fetch data from the
   * server and update the state of the component.
   */
  async componentDidMount(): Promise<void> {
    this.setState({
      loading: true,
      loadingText: 'Loading Application Link...',
    });
    if (this.props.noLoginEdit) {
      this.setState(
        {
          login: false,
          loading: false,
          noLoginEdit: true,
          loadingText: 'Loading...',
        },
        async () => {
          await this.getApplicationFromApi(
            this.props.email,
            this.props.referenceId
          );
        }
      );
    } else if (this.props.edit) {
      this.setState({
        login: true,
        loading: false,
        loadingText: 'Loading...',
      });
    } else {
      let config: any = null;
      if (this.props.config !== null || this.props.config !== undefined) {
        const configMd5 = Buffer.from(
          this.props.config || 'e30=',
          'base64'
        ).toString('utf-8');
        config = JSON.parse(configMd5);
      }
      try {
        const data = await this.props.boarding.boardingService.default_get(
          process.env.REACT_APP_TOKEN,
          this.props.match.params.id
        );
        this.setState(
          {
            login: false,
            boardingMode: this.props.mode,
            config,
          },
          () => {
            const builderData = data;
            if (data?.data) {
              builderData.data.properties = config?.boardingConfig;
            }
            if (config !== null) {
              window.parent.postMessage(
                {
                  event: `callback-payabli-function-mounted-component-${config?.embededToken}`,
                  data: true,
                },
                '*'
              );
              this.resizeContainer();
              window.addEventListener('resize', this.resizeContainer);
            }
            this.setBuilderData(builderData);
          }
        );
      } catch (err: any) {
        if (err) {
          const error =
            err?.response?.status >= 400 && err?.response?.status <= 500
              ? MESSAGE.ERROR_404
              : String(err);
          this.setState({
            login: false,
            loading: false,
            noLoginEdit: true,
            error,
            reloadError: true,
          });
        }
      }
    }
  }

  /**
   * It's a function that navigates to the next tab in the tab list
   */
  async navigateAllTabs(): Promise<void> {
    const objThis = this;
    // debugger
    objThis.setState({ loading: false }, () => {
      // setTimeout(function () {
      let tabActive = null;
      if (objThis.state.activePos.includes(0)) {
        if (tabActive === null) {
          tabActive = 0;
        }
        objThis.gotoNextTab(0);
      }
      if (objThis.state.activePos.includes(1)) {
        if (tabActive === null) {
          tabActive = 1;
        }
        objThis.gotoNextTab(1);
      }

      if (objThis.state.activePos.includes(2)) {
        if (tabActive === null) {
          tabActive = 2;
        }
        objThis.gotoNextTab(2);
      }
      // objThis.gotoNextTab(3);
      const { validTabs } = objThis.state;
      if (objThis.state.activePos.includes(3)) {
        if (tabActive === null) {
          tabActive = 3;
        }
        validTabs[3] = false;
      }
      // objThis.setState({ validTabs: validTabs });
      if (objThis.state.activePos.includes(4)) {
        if (tabActive === null) {
          tabActive = 4;
        }
        objThis.gotoNextTab(4);
      }
      objThis.gotoNextTab(tabActive as number);

      // }, 1000);
    });
  }

  /**
   * It sets the current position of the tab and then scrolls to the top of the page
   * @param {number} position - The current tab position
   * @returns the current position of the tab.
   */
  gotoNextTab(position: number): void {
    const { validTabs } = this.state;
    if (this.props.boarding.isValidTab(this.state.currentPos)) {
      validTabs[this.state.currentPos] = true;
    } else {
      validTabs[this.state.currentPos] = false;
    }

    if (position > 5) {
      return;
    }
    const actv = this.state.activePos;
    if (actv.indexOf(position) < 0) {
      position += 1;
      this.gotoNextTab(position);
      return;
    }
    if (position === 3) {
      this.setState({ tab_ser_pric: true });
    }

    if (position === 5) {
      position = 4;
    }

    this.setState({ validTabs, currentPos: position }, () => {
      this.setCurrentSection(position);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    });
  }

  /**
   * If the current tab is valid, set the validTabs array to true at the current position. If the
   * current tab is not valid, set the validTabs array to false at the current position. If the
   * position is less than 0, return. If the position is not in the activePos array, call the function
   * again with the position minus 1. If the position is 5, set the position to 4. Set the currentPos
   * state to the position. Set the currentSection state to the position. Scroll to the top of the page
   * @param {number} position - The position of the tab you want to go to.
   * @returns the current position of the tab.
   */
  gotoPrevTab(position: number): void {
    const { validTabs } = this.state;
    if (this.props.boarding.isValidTab(this.state.currentPos)) {
      validTabs[this.state.currentPos] = true;
    } else {
      validTabs[this.state.currentPos] = false;
    }
    this.setState({ validTabs });

    if (position < 0) {
      return;
    }
    const actv = this.state.activePos;
    if (actv.indexOf(position) < 0) {
      position -= 1;
      this.gotoPrevTab(position);
      return;
    }

    if (position === 5) {
      position = 4;
    }

    this.setState({ currentPos: position });
    this.setCurrentSection(position);
    this.props.boarding.setNextClicked(true);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  /**
   * If the user clicks the 'Next' button, the function checks to see if the current tab is valid. If
   * it is, it sets the state of the validTabs array to true. If it's not, it sets the state of the
   * validTabs array to false. Then, it checks to see if there are any false values in the validTabs
   * array. If there are, it displays an error message and stays on the current tab. If there aren't,
   * it moves to the next tab and submits the form
   * @param {number} position - number - The position of the tab you want to go to.
   */
  gotoNextTabAndSubmit(position: number, onlyCheck?: boolean): void {
    const termsConditionsData = this.state.middlewareResult.termsConditions;
    const { validTabs } = this.state;
    for (let tab = 0; tab < 5; tab++) {
      if (this.props.boarding.isValidTab(tab)) {
        validTabs[tab] = true;
      } else {
        validTabs[tab] = false;
      }
      this.props.boarding.setNextClicked(true);
    }
    this.setState({ validTabs }, () => {
      const index = validTabs.findIndex((tab: any) => tab === false);
      const boardingData = this.props.boarding;
      const percentageProc =
        +boardingData?.processing?.binperson +
        +boardingData?.processing?.binphone +
        +boardingData?.processing?.binweb;
      if (index > -1 && !onlyCheck) {
        this.gotoNextTab(index);
        toast.error(
          'Please review the information required or with errors and try again.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
          }
        );
      } else if (
        this.state.middlewareResult.validFields.validPercentOfPayments &&
        percentageProc !== 100 &&
        !onlyCheck
      ) {
        this.gotoNextTab(2);
        toast.error('Combined total payments percent must be equal to 100%!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
      } else if (!this.props.boarding.atLeastDocuments() && !onlyCheck) {
        this.gotoNextTab(4);
        toast.error(
          `Please, you must attach at least ${this.props.boarding.minimumDocuments} document(s)`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
          }
        );
      } else if (
        termsConditionsData &&
        !this.props.boarding.signer.acceptance &&
        !onlyCheck
      ) {
        toast.error(
          'Please accept the terms and conditions of application before saving it.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
          }
        );
      } else if (!onlyCheck) {
        this.gotoNextTab(position);
        this.submitComplete();
      }
    });
  }

  /* The above code is a function that returns a modal. */
  getFinishedModal(): React.ReactNode {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: CheckApplication,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return (
      <div>
        <div className="modal-content">
          {/* <BiCheckCircle className='icon-modal' /> */}
          <Lottie options={defaultOptions} height={100} width={100} />
          <h4>Application Submitted!</h4>
          <p className="small">Your application is on the way.</p>
          {this.state.appemail !== null && (
            <p>
              An email will be sent to you with any update about your boarding
              status.
            </p>
          )}
        </div>
        <div className="modal-content">
          <h4 className="primary-text">You can close this window.</h4>
        </div>
      </div>
    );
  }

  closefileExceededSize(): void {
    this.props.boarding.setFileExceededSize(false);
  }

  getfileExceededSizeModal(): React.ReactNode {
    return (
      <Modal
        size="tiny"
        dimmer="blurring"
        closeIcon
        closeOnDimmerClick={false}
        open={this.props.boarding.fileExceededSize}
        onClose={(): void => this.closefileExceededSize()}
      >
        <Modal.Content>
          <div className="modal-content">
            <BiX className="icon-modal" />
            <h3>File limit exceeded!</h3>
            <small>
              It seems like you’ve exceeded the maximum file size allowed.
              Upload max is 29MB of any combination of .pdf .jpg. or .png files.
              We apologize for any inconvenience.
            </small>

            <small>If you need to submit larger files, please email us.</small>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: 'center' }}>
          <Button
            basic
            color="black"
            onClick={(): void => this.closefileExceededSize()}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  /* The above code is a React Modal that is used to collect the user's email address. */
  getEmailModal(): React.ReactNode {
    return (
      <Modal
        size="mini"
        dimmer="blurring"
        closeIcon
        closeOnDimmerClick={false}
        open={this.state.saveEmailModalIsOpen}
        onClose={(): void => this.closeModalNoAction()}
      >
        <Modal.Content>
          <div className="modal-content">
            <BiEnvelope className="icon-modal" />
            <h3>Enter an Email</h3>
            <small>
              Please enter your email address to receive an access link you may
              use to finish this application later.
            </small>
            <br />
            <label style={{ marginTop: '1rem' }}>Email address</label>
            <Input
              focus
              error={this.state.modalEmailError}
              type="email"
              style={{
                width: '100%',
                fontSize: '1rem',
                color: '#212529',
              }}
              placeholder="name@example.com"
              onChange={this.handleChangeEmail}
            />
          </div>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: 'center' }}>
          <Button
            basic
            color="black"
            onClick={(): void => this.closeSaveEmailModal()}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  /* The above code is a function that returns a modal to choice between continue editing or exit and
   * enter a new application.
   */
  getSaveToCompleteLaterModal(): React.ReactNode {
    return (
      <Modal
        size="tiny"
        dimmer="blurring"
        closeOnDimmerClick={false}
        open={this.state.saveLinkModalIsOpen}
      >
        <Modal.Content>
          <div className="modal-content">
            <BiCheckCircle className="icon-modal" />
            <h5>Application Saved!</h5>
            {this.state.appemail !== null && (
              <>
                <p>
                  Check{' '}
                  <a href={`mailto:${this.state.appemail}`}>
                    {this.state.appemail}
                  </a>{' '}
                  for a link you may use to access and complete the application
                  later.
                </p>
              </>
            )}
          </div>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: 'center' }}>
          <Button
            basic
            color="black"
            onClick={(): void => this.closeSaveLinkModalContinue()}
          >
            CONTINUE WITH APPLICATION
          </Button>
          <Button
            inverted
            color="red"
            onClick={(): void => this.closeSaveLinkModal()}
          >
            EXIT APPLICATION
          </Button>
          <Message negative size="mini">
            <p>
              <Icon name="warning" size="large" />
              After exiting, you will be redirected to a blank application link.
            </p>
          </Message>
        </Modal.Actions>
      </Modal>
    );
  }

  /* Closing the modal and reloading the page. */
  closeSaveLinkModal(): void {
    this.setState({ saveLinkModalIsOpen: false });
    window.location.reload();
  }

  /* A function that closes the save link modal. */
  closeSaveLinkModalContinue(): void {
    this.setState({ saveLinkModalIsOpen: false });
  }

  /* The above code is a function that is called when the user clicks the 'Save' button in the modal.
  It checks to see if the email is valid, and if it is, it sets the state of the modal to open and
  the email error to true. If the email is not valid, it sets the state of the modal to close and
  the email error to false. */
  closeSaveEmailModal(): void {
    if (validators.stringValidator('email', this.state.appemail)) {
      this.setState({
        saveEmailModalIsOpen: true,
        modalEmailError: true,
      });
    } else
      this.setState(
        { saveEmailModalIsOpen: false, modalEmailError: false },
        this.saveToCompleteLater
      );
  }

  /* A function that closes the modal. */
  closeModalNoAction(): void {
    this.setState({ saveEmailModalIsOpen: false, appemail: null });
  }

  /* Reloading the page when the endLinkModalIsOpen is true. */
  /*   closeEndLinkModal(): void {
    if (this.state.endLinkModalIsOpen) {
      window.location.reload();
    }
  } */

  /* Calling the create or update function from the boarding service. */
  /* Saving the application data to the database. */
  async saveApplication(
    signatureRequestId: string,
    update?: boolean,
    validateBeforeSave?: boolean
  ): Promise<any> {
    this.setState({ loading: true, loadingText: 'Saving Application...' });
    const appData = this.props.boarding.getData(
      this.state.orgId,
      this.state.tid,
      signatureRequestId,
      this.state.appemail
    );
    const createOrUpdateParams = [process.env.REACT_APP_TOKEN, appData];
    if (this.state.update) {
      createOrUpdateParams.push(this.state.appId);
    }
    createOrUpdateParams.push(validateBeforeSave ?? false);
    if (!this.state.update && !update) {
      return await this.props.boarding.boardingService.create(
        ...createOrUpdateParams
      );
    }
    return await this.props.boarding.boardingService.update(
      ...createOrUpdateParams
    );
  }

  normalizeServices(services: any): any {
    return {
      card: {
        price: {
          body: services?.card?.price?.body,
        },
        fees: {
          body: services?.card?.fees?.body,
        },
        pricingType: services?.card?.pricingType?.value,
        discountFrequency: services?.card?.discountFrequency?.value,
        fundingRollup: services?.card?.fundingRollup?.value,
        pdfTemplateId: services?.card?.pdfTemplateId?.value,
      },
      ach: {
        price: {
          body: services?.ach?.price?.body,
        },
        fees: {
          body: services?.ach?.fees?.body,
        },
        pricingType: services?.ach?.pricingType?.value,
        discountFrequency: services?.ach?.discountFrequency?.value,
        fundingRollup: services?.ach?.fundingRollup?.value,
        pdfTemplateId: services?.ach?.pdfTemplateId?.value,
      },
      mgm: {
        price: {
          body: services?.mgm?.price?.body,
        },
        fees: {
          body: services?.mgm?.fees?.body,
        },
        pricingType: services?.mgm?.pricingType?.value,
        discountFrequency: services?.mgm?.discountFrequency?.value,
        pdfTemplateId: services?.mgm?.pdfTemplateId?.value,
      },
    };
  }

  async generatePDF(): Promise<any> {
    const { services } = this.state.middlewareResult;
    this.setState({ loading: true, loadingText: 'Saving Application...' });
    const appData = this.props.boarding.getDataToPDF(
      this.state.orgId,
      this.state.tid
    );
    return this.props.boarding.boardingService.generatePDF(
      appData,
      this.normalizeServices(services)
    );
  }

  callbackFunction(): void {
    if (window && window.parent) {
      window.parent.postMessage(
        {
          type: 'callback',
          message: 'callbackFunction',
        },
        '*'
      );
    }
  }

  resizeContainer(): void {
    const config = this.state?.config;
    window.parent.postMessage(
      {
        event: `callback-payabli-function-resize${config?.randomId}`,
        data: document.body.scrollHeight,
      },
      '*'
    );
  }

  functionCallBackSuccess(data: any): void {
    const config = this.state?.config;
    window.parent.postMessage(
      {
        event: `callback-payabli-function-done${config?.randomId}`,
        data,
      },
      '*'
    );
  }

  functionCallBackError(data: any): void {
    const config = this.state?.config;
    window.parent.postMessage(
      {
        event: `callback-payabli-function-error${config?.randomId}`,
        data,
      },
      '*'
    );
  }

  functionCallBackReady(): void {
    if (!this.props.vTerminal.hasPaymentPageErrors()) {
      const { config } = this.state;
      window.parent.postMessage(
        {
          event: `callback-payabli-function-ready${config?.randomId}`,
          data: true,
        },
        '*'
      );
    }
  }

  /* The above code is checking if the state of appemail is null. If it is null, it will open a modal.
  If it is not null, it will save the application. */
  async saveToCompleteLater(): Promise<void> {
    if (
      this.state.appemail === null &&
      !this.state.editMode &&
      parseInt(this.state.boardingMode, 10) !== 25
    ) {
      this.setState({ saveEmailModalIsOpen: true });
    } else {
      try {
        const result: any = await this.saveApplication('');
        toast.success('Application saved successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-success-container',
        });
        const stateTmp: any = {
          appId: result.data.responseData,
          loading: false,
          update: true,
          loadingText: 'Loading...',
        };
        if (this.state.appemail !== null || this.state.editMode) {
          stateTmp.saveLinkModalIsOpen = true;
        }
        this.setState(stateTmp, () => {
          this.props.boarding.attachments.elementsList
            .filter((notDeleted: any) => notDeleted.status === STATUS.deleted)
            .map((element: any) => {
              element.status = STATUS.toDelete;
              this.props.boarding.attachments.removeAttachment(element.id);
            });
          this.props.boarding.attachments.setPushed();
          this.props.boarding.owners.setPosted();
          this.props.boarding.contacts.setPosted();
          this.callbackFunction();
        });
      } catch (err: any) {
        err;
        toast.error('Oops! Something was wrong, please review and try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
        this.setState({
          loading: false,
          loadingText: 'Loading...',
        });
      }
    }
  }

  async actionSaveApplication(
    signatureRequestId: string,
    update?: boolean,
    notShowToast?: boolean,
    validateBeforeSave?: boolean
  ): Promise<any | void> {
    try {
      const result = await this.saveApplication(
        signatureRequestId,
        update,
        validateBeforeSave
      );
      if (!notShowToast) {
        toast.success('Application saved successfully!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-success-container',
        });
      }
      this.setState({
        appId: result.data.responseData,
        loading: true,
        update: true,
        loadingText: 'Updating application status...',
      });
      return result.data.responseData;
    } catch (err: any) {
      err;
      if (!notShowToast) {
        toast.error('Oops! Something was wrong, please review and try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        });
      } else {
        throw err;
      }
    } finally {
      this.setState({
        loading: false,
        loadingText: 'Loading...',
      });
    }
  }

  /* The above code is a function that is called when the user clicks the submit button. The function
  first saves the application and then updates the application status. */
  async submitComplete(): Promise<void> {
    const CLIENT_ID = process.env.REACT_APP_HELLOSIGN_CLIENT_ID;
    const { services } = this.state.middlewareResult;
    let [saved, signed, updated] = [false, false, false];
    if (
      ((!services?.card?.acceptance && !services?.card?.pdfTemplateId) ||
        !services?.card?.pdfTemplateId?.value) &&
      ((!services?.ach?.acceptance && !services?.ach?.pdfTemplateId) ||
        !services?.ach?.pdfTemplateId.value)
    ) {
      try {
        const appId = await this.actionSaveApplication('', false, true, true);
        const self = this;
        await this.props.boarding.boardingService.updateApplicationStatus(
          appId,
          4
        );
        this.setState(
          {
            loading: false,
            endLinkModalIsOpen: true,
            loadingText: 'Loading...',
          },
          () => {
            self.callbackFunction();
          }
        );
      } catch (err: any) {
        err;
        const toastFunc = !saved
          ? toast.error
          : !signed
          ? toast.warning
          : toast.warn;
        const additionalText =
          saved && !signed
            ? 'The application was saved successfully but could not be signed.'
            : signed && !updated
            ? 'The application was saved and signed successfully but could not be signed.'
            : '';
        toastFunc(
          `${
            err?.message
              ? `${additionalText} ${err.message}`
              : 'Oops! Something was wrong, please review and try again.'
          }`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
          }
        );
        this.setState({
          loading: false,
          loadingText: 'Loading...',
        });
      }
    } else {
      try {
        await this.actionSaveApplication('', false, true, true);
        saved = true;
        const res: any = await this.generatePDF();
        signed = true;
        const data = res?.data?.embeddedUrl;
        const signatureRequestId = res?.data?.signatureRequestId;
        const client = new HelloSign();
        client.open(data, {
          clientId: CLIENT_ID,
        });

        client.once('sign', async () => {
          this.setState({
            loading: false,
            loadingText: 'Loading...',
          });
          await this.actionSaveApplication(
            signatureRequestId,
            true,
            true,
            true
          );
          updated = true;
          await this.props.boarding.boardingService
            .updateApplicationStatus(this.state.appId, 4)
            .then(() => {
              this.setState({
                loading: false,
                loadingText: 'Loading...',
              });
              this.callbackFunction();
            })
            .catch(() => {
              this.setState({
                endLinkModalIsOpen: true,
                loading: false,
                loadingText: 'Loading...',
              });
            });
        });

        client.once('close', () => {
          this.setState({
            loading: false,
            endLinkModalIsOpen: true,
            loadingText: 'Loading...',
          });
        });

        client.once('cancel', () => {
          this.setState({
            loading: false,
            loadingText: 'Loading...',
          });
        });
      } catch (err: any) {
        err;
        const toastFunc = !saved
          ? toast.error
          : !signed
          ? toast.warning
          : toast.warn;
        const additionalText =
          saved && !signed
            ? 'The application was saved successfully but could not be signed.'
            : signed && !updated
            ? 'The application was saved and signed successfully but could not be signed.'
            : '';
        toastFunc(
          `${
            err?.message
              ? `${additionalText} ${err.message}`
              : 'Oops! Something was wrong, please review and try again.'
          }`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
          }
        );
        this.setState({
          loading: false,
          loadingText: 'Loading...',
        });
      }
    }
  }

  isHideTab(dataValue: any): boolean {
    if (dataValue === undefined) {
      return false;
    }
    const result =
      Object.entries(dataValue || [])?.length > 0
        ? Object.entries(dataValue || [])
            .map((element) => element[1])
            .filter((data: any) => data?.visible)
        : [];
    return result.length === 0;
  }

  /**
   * When the user clicks on a section, the currentSection state is set to the name of the section that
   * was clicked.
   * @param {number} position - number - the position of the tab that was clicked
   */
  setCurrentSection(position: number): void {
    switch (position) {
      case 0:
        this.setState({ currentSection: 'business' });
        break;
      case 1:
        this.setState({ currentSection: 'owners' });
        break;
      case 2:
        this.setState({ currentSection: 'processing' });
        break;
      case 3:
        this.setState({ currentSection: 'services' });
        break;
      case 4:
      default:
        this.setState({ currentSection: 'banking' });
        break;
    }
  }

  printPDF(): void {
    this.props.boarding.boardingService.exportPdf();
  }

  /**
   * It takes the validTabs array, loops through it, and if the value is true, it adds 20 to the
   * percent
   * @returns The percentage of the progress bar.
   */
  getProgressBarPercent(): number {
    const { validTabs, activePos } = this.state;
    let percent = 0;
    const activeTabs = activePos.filter((e: any) => e < 5);
    const valueSum =
      (activeTabs.length === 1 && validTabs.length === 1) ||
      (activeTabs.length === 1 && validTabs.length >= 4 && !validTabs[4])
        ? 0
        : 100 / activeTabs.length;
    validTabs.forEach((element: any) => {
      if (element === true) {
        if (Number((percent + valueSum).toFixed(2)) === 33.33) {
          percent = Number((percent + valueSum + 0.01).toFixed(2));
        } else {
          percent = Number((percent + valueSum).toFixed(2));
        }
      }
    });
    return percent;
  }

  getMetaDataComponent(dataComponent: any, componentName: string): any {
    Object.entries(dataComponent || []).map(
      (component: any, index: number): any => {
        const inputName = component[0];
        const componentItem = dataComponent[inputName];
        if (inputName === undefined || inputName === '') {
          return null;
        }
        if (componentItem === undefined || componentItem === '') {
          return null;
        }
        const value: any = componentItem?.value;
        const fieldMetadata = {
          name: inputName,
          storeName: inputName,
          restrictedDob: new Date(),
          label: componentItem?.label,
          toolTip: componentItem?.toolTip,
          index,
          required: componentItem?.required,
          readonly: componentItem?.readonly,
          value: value || this.props.boarding[componentName][inputName],
          boarding: this.props.boarding,
          identifyField: inputName[0],
          metadata: {},
          component: componentName,
        };
        this.props.boarding[fieldMetadata.component].setProp(
          inputName,
          fieldMetadata.value,
          fieldMetadata.metadata
        );
        this.props.boarding[fieldMetadata.component].setError(
          inputName,
          false,
          fieldMetadata.metadata
        );
      }
    );
  }

  /* The above code is a function that returns an object. The object has a menuItem property and a
  render property. The menuItem property is an object that has a key property and a content
  property. The content property is a div element that has a className property. The className
  property is a ternary operator that checks if the state's validTabs array has a value of true at
  index 0. If it does, the className property is set to 'green-text'. If it doesn't, the className
  property is set to an empty string. The render property is a */
  businessTab(): any {
    const businessData = this.state.middlewareResult.businessDetails;
    if (this.isHideTab(businessData)) {
      this.getMetaDataComponent(businessData, 'businessDetails');
      return;
    }

    return {
      menuItem: {
        key: 'businessDetails',
        content: (
          <div
            key="businessDetails-header"
            className={
              this.state.validTabs[0] === true
                ? 'tabs-config tabs-color-text green-text'
                : 'tabs-config'
            }
          >
            <BiDetail className="icon" key="businessDetails-icon" />
            <br key="businessDetails-br" />
            <span key="businessDetails-text">Business Details</span>
          </div>
        ),
      },
      render: (): React.ReactNode => (
        <Tab.Pane
          key="tab-businessDetails"
          className="tab-pane"
          attached={false}
        >
          <div className="mb-4" key="business-details-objheader">
            <h3 data-qaid="openApplicationPage">Business Details</h3>
            <small className="small mb-4 dark-grey">
              You have an awesome business, tell us a little bit about it!
            </small>
          </div>
          <BusinessDetail
            key="business-details-obj"
            businessData={businessData}
          />
        </Tab.Pane>
      ),
    };
  }

  /* The above code is creating a tab for the Ownership and Contacts section of the application. */
  ownerContactTab(): any {
    const ownerData = this.state.middlewareResult.owners;
    const ownerBuilder =
      this.state.middlewareResult.builderData.owners.own_list;
    const businessData = this.state.middlewareResult.businessDetails;
    const showOwners = businessData.btype?.value
      ? !(
          (/Non-Profit Org|Government/.test(businessData.btype.value) &&
            businessData.btype.value ===
              this.props.boarding.businessDetails.btype) ||
          /Non-Profit Org|Government/.test(
            this.props.boarding.businessDetails.btype
          )
        )
      : Array.isArray(ownerData)
      ? ownerData.find((owner: any) =>
          JSON.stringify(owner).includes('"visible":true')
        ) !== undefined
      : true;
    const contactBuilder =
      this.state.middlewareResult.builderData.owners.contact_list;
    const contactData = this.state.middlewareResult.contacts;
    const showContactData = Array.isArray(contactData)
      ? contactData.find((contact: any) =>
          JSON.stringify(contact).includes('"visible":true')
        ) !== undefined
      : true;
    const { multipleOwners } =
      this.state.middlewareResult.builderData.attributes;
    const { multipleContacts } =
      this.state.middlewareResult.builderData.attributes;
    if (!showOwners && ownerData.length > 0)
      this.getMetaDataComponent(ownerBuilder, 'owners');
    if (!showContactData && contactBuilder.length > 0)
      this.getMetaDataComponent(contactBuilder, 'contacts');
    if (!showOwners && !showContactData) return;

    if (Array.isArray(ownerData) && Array.isArray(contactData)) {
      if (this.isHideTab(ownerData[0]) && this.isHideTab(contactData[0])) {
        this.getMetaDataComponent(ownerBuilder, 'owners');
        this.getMetaDataComponent(contactBuilder, 'contacts');
        return;
      }
    }

    return {
      menuItem: {
        key: 'owners',
        content: (
          <div
            className={
              this.state.validTabs[1] === true
                ? 'tabs-config tabs-color-text green-text'
                : 'tabs-config'
            }
          >
            <BiUserCheck className="icon" />
            <br />
            <span>Ownership and Contacts</span>
          </div>
        ),
      },
      render: (): React.ReactNode => (
        <Tab.Pane
          key="tabpane-ownercontact"
          className="tab-pane"
          attached={false}
        >
          <div className="mb-4" key="div-owners-div1">
            <div className="mb-4">
              <h3>Ownership and Contacts</h3>
              <small className="small mb-4 dark-grey">
                Federal Law requires us to collect some information on the
                owners, the business, and whoever will be signing the Merchant
                Agreement.
              </small>
            </div>
            {showOwners &&
              this.props.boarding.owners.elementsList.map(
                (owner: any, ownerIndex: number) => {
                  return (
                    <div key={`owners-div${ownerIndex}`}>
                      <Divider />
                      <div className="owner-header-div">
                        <div className="owner-header-info">
                          <h5> Owner Information</h5>
                          <p>For Business Owner #{ownerIndex + 1}</p>
                        </div>
                        <div className="owner-header-icon">
                          {ownerIndex > 0 && (
                            <>
                              <div />
                              <Button
                                color="red"
                                className="hide-sm delete-button-owner"
                                size="tiny"
                                icon
                                labelPosition="right"
                                onClick={(): void => {
                                  this.props.boarding.owners.remove(owner.id);
                                }}
                              >
                                {this.state.boardingConfiguration
                                  ?.boardingOwnerDeleteButtonText ||
                                  'Delete Owner'}
                                <Icon name="trash" />
                              </Button>
                              <Button
                                className="hide show-sm delete-button-owner"
                                basic
                                color="red"
                                size="mini"
                                icon
                                onClick={(): void => {
                                  this.props.boarding.owners.remove(owner.id);
                                }}
                              >
                                <Icon name="trash" size="large" />
                              </Button>
                            </>
                          )}
                        </div>
                        <br />
                      </div>

                      <Ownership
                        key={`o${owner.id}`}
                        ownerData={ownerData}
                        ownerBuilder={ownerBuilder}
                        ownerInfo={owner}
                        ownerIndex={ownerIndex}
                      />
                      <br />
                    </div>
                  );
                }
              )}
            {showOwners &&
              Object.keys(ownerData).length > 0 &&
              this.props.boarding.owners.elementsList.length >= 0 &&
              multipleOwners && (
                <button
                  key="addOwner"
                  style={{ width: '100%' }}
                  className="btn bordered no-margin add-button-owner"
                  onClick={(): void => {
                    this.props.boarding.owners.addOwner();
                  }}
                >
                  {this.state.boardingConfiguration
                    ?.boardingContactAddButtonText ||
                    'Add additional Owner with 25% or more of Ownership'}
                </button>
              )}
          </div>
          {contactData &&
            showContactData &&
            contactBuilder &&
            Object.keys(contactData[0]).length > 0 && (
              <div key="contacts-div">
                {this.props.boarding.contacts.elementsList.map(
                  (contact: any, contactIndex: number) => {
                    return (
                      <>
                        <Divider />
                        <div
                          className={`mb-m2 owner-header-div${contactIndex}`}
                        >
                          <div className="owner-header-info">
                            <div>
                              <h5> Contact Information</h5>
                              <p>For Business Contact #{contactIndex + 1} </p>
                            </div>
                          </div>
                          <div className="owner-header-icon">
                            <CopyInfoInput
                              copyText="Copy info from owner #1"
                              copyHandlerClick={(e: any): void =>
                                this.onCopyOwnerInfo(e, contact.id)
                              }
                            />
                            {contactIndex > 0 && (
                              <>
                                <Button
                                  className="hide-sm delete-button-contact"
                                  color="red"
                                  size="tiny"
                                  icon
                                  labelPosition="right"
                                  onClick={(): void => {
                                    this.props.boarding.contacts.remove(
                                      contact.id
                                    );
                                  }}
                                >
                                  {this.state.boardingConfiguration
                                    ?.boardingContactDeleteButtonText ||
                                    'Delete Contact'}
                                  <Icon name="trash" />
                                </Button>
                                <Button
                                  className="hide show-sm delete-button-contact"
                                  basic
                                  color="red"
                                  size="mini"
                                  icon
                                  onClick={(): void => {
                                    this.props.boarding.contacts.remove(
                                      contact.id
                                    );
                                  }}
                                >
                                  <Icon name="trash" size="large" color="red" />
                                </Button>
                              </>
                            )}
                          </div>
                          <br />
                        </div>
                        {contactBuilder && (
                          <OwnerContact
                            key={`c${contact.id}`}
                            contactData={contactData}
                            contactBuilder={contactBuilder}
                            contactInfo={contact}
                            contactIndex={contactIndex}
                          />
                        )}
                      </>
                    );
                  }
                )}
                <div style={{ marginTop: '2rem' }}>
                  {Object.keys(contactData).length > 0 &&
                    this.props.boarding.contacts.elementsList.length >= 0 &&
                    multipleContacts && (
                      <button
                        key="addContact"
                        style={{
                          width: '100%',
                          marginTop: '2rem',
                        }}
                        className="btn bordered no-margin add-button-contact"
                        onClick={(): void => {
                          this.props.boarding.contacts.addContact();
                        }}
                      >
                        {this.state.boardingConfiguration
                          ?.boardingContactAddButtonText || 'Add Contact'}
                      </button>
                    )}
                </div>
              </div>
            )}
        </Tab.Pane>
      ),
    };
  }

  /* The above code is a function that returns an object. The object has two properties, menuItem and
  render. The menuItem property is an object that has a key and content property. The content
  property is a div that contains an icon and a span. The render property is a function that returns
  a Tab.Pane component. The Tab.Pane component has a className property and a attached property. The
  attached property is set to false. The Tab.Pane component contains a div and a Processing
  component. The Processing component has a processingData property. The processingData property is
  set to */
  processingTab(): any {
    const processingData = this.state.middlewareResult.processing;
    if (this.isHideTab(processingData)) {
      this.getMetaDataComponent(processingData, 'processing');
      return;
    }

    return {
      menuItem: {
        key: 'processing',
        content: (
          <div
            className={
              this.state.validTabs[2] === true
                ? 'tabs-config tabs-color-text green-text'
                : 'tabs-config'
            }
          >
            <BiCog className="icon" />
            <br />
            <span>Processing Information</span>
          </div>
        ),
      },
      render: (): React.ReactNode => (
        <Tab.Pane
          key="tab-processing-info"
          className="tab-pane"
          attached={false}
        >
          <div className="mb-4">
            <h3>Processing Information</h3>
            <small className="small mb-4 dark-grey">
              We are so proud to power your payment processing, share with us
              your needs.
            </small>
          </div>
          <Processing processingData={processingData} />
        </Tab.Pane>
      ),
    };
  }

  /* The above code is a function that returns an object. The object has a menuItem property and a
  render property. The menuItem property is an object that has a key property and a content
  property. The content property is a div that contains a BiDollarCircle component, a br tag, and a
  span tag. The render property is a function that returns a Tab.Pane component. The Tab.Pane
  component has a className property and an attached property. The className property is set to
  'tab-pane' and the attached property is set to false. */
  hideServicesData(serviceData: any): boolean {
    return (
      serviceData &&
      serviceData?.card !== null &&
      (!serviceData?.card?.acceptance || !serviceData?.card?.price?.header) &&
      !(
        serviceData?.card?.fees?.header?.columns?.filter(
          (col: any) => col?.visible
        ).length > 0
      ) &&
      serviceData &&
      serviceData?.ach !== null &&
      (!serviceData?.ach?.acceptance || !serviceData?.ach?.price?.header) &&
      !(
        serviceData?.ach?.fees?.header?.columns?.filter(
          (col: any) => col?.visible
        ).length > 0
      ) &&
      serviceData &&
      serviceData?.mgm !== null &&
      (!serviceData?.mgm?.acceptance || !serviceData?.mgm?.price?.header) &&
      !(
        serviceData?.mgm?.fees?.header?.columns?.filter(
          (col: any) => col?.visible
        ).length > 0
      )
    );
  }

  servicesTab(): any {
    const serviceData = this.state.middlewareResult.services;
    if (
      Object.entries(serviceData || [])
        .map((element) => element[1])
        .filter(
          (data: any) =>
            data?.visible && (data.price !== null || data.fees !== null)
        ).length === 0
    ) {
      return;
    }
    if (this.hideServicesData(serviceData)) {
      return;
    }
    return {
      menuItem: {
        key: 'services',
        content: (
          <div
            className={
              this.state.validTabs[3] === true
                ? 'tabs-config tabs-color-text green-text'
                : 'tabs-config'
            }
          >
            <BiDollarCircle className="icon" />
            <br />
            <span>Services and Pricing</span>
          </div>
        ),
      },
      render: (): React.ReactNode => (
        <Tab.Pane key="tab-services" className="tab-pane" attached={false}>
          <Pricing serviceData={serviceData} />
        </Tab.Pane>
      ),
    };
  }

  /* The above code is a function that returns an object. The object has a menuItem property and a
  render property. The menuItem property is an object that has a key property and a content
  property. The content property is a div that contains an icon and a span. The render property is a
  function that returns a Tab.Pane component. The Tab.Pane component has a className property and an
  attached property. The className property has a value of 'tab-pane'. The attached property has a
  value of false. The Tab.Pane component contains a div, a h */
  bankigTab(): any {
    const bankingData = this.state.middlewareResult.banking;
    const termsConditionsData = this.state.middlewareResult.termsConditions;
    const signerData = this.state.middlewareResult.signer;
    const ownerData = this.state.middlewareResult.owners;
    const contactData = this.state.middlewareResult.contacts;
    const depositVisible =
      this.state.middlewareResult.builderData.banking.depositAccount?.visible;
    const withdrawalVisible =
      this.state.middlewareResult.builderData.banking.withdrawalAccount
        ?.visible;
    const minimumDocuments = this.state.middlewareResult.builderData?.attributes
      .minimumDocuments
      ? this.state.middlewareResult.builderData?.attributes.minimumDocuments
      : 1;
    const uploadDocuments =
      this.state.middlewareResult.builderData.banking?.uploadDocuments;

    const businessData = this.state.middlewareResult.businessDetails;
    const showOwners = businessData.btype?.value
      ? !(
          (/Non-Profit Org|Government/.test(businessData.btype.value) &&
            businessData.btype.value ===
              this.props.boarding.businessDetails.btype) ||
          /Non-Profit Org|Government/.test(
            this.props.boarding.businessDetails.btype
          )
        )
      : Array.isArray(ownerData)
      ? ownerData.find((owner: any) =>
          JSON.stringify(owner).includes('"visible":true')
        ) !== undefined
      : true;

    return {
      menuItem: {
        key: 'banking',
        content: (
          <div
            className={
              this.state.validTabs[4] === true
                ? 'tabs-config tabs-color-text green-text'
                : 'tabs-config'
            }
          >
            <RiBankLine className="icon" />
            <br />
            <span>Banking and Documents</span>
          </div>
        ),
      },
      render: (): React.ReactNode => (
        <Tab.Pane key="tab-banking" className="tab-pane" attached={false}>
          <div className="mb-4">
            <h3>Banking and Documents</h3>
            <small className="small mb-4 dark-grey">
              We are almost done! Please provide us with some important
              supporting documents and where you want us to send your funds.
            </small>
          </div>
          <Banking
            bankingData={bankingData}
            zipFile={this.state.zipFile}
            depositVisible={depositVisible}
            withdrawalVisible={withdrawalVisible}
            minimumDocuments={minimumDocuments}
            uploadDocuments={uploadDocuments}
          />
          <Divider />
          <h3 className="mb-4">Authorized Signer Information</h3>
          <Signer
            signerData={signerData}
            owners={Object.keys(ownerData).length > 0}
            contacts={Object.keys(contactData).length > 0}
            showOwners={showOwners}
          />
          <Divider className="mb-4" />
          {termsConditionsData && (
            <TermsConditions
              termsConditionsData={termsConditionsData}
              generatePDF={this.generatePDF}
              gotoNextTabAndSubmitOnlyCheck={this.gotoNextTabAndSubmit}
              gotoTabWithError={this.gotoNextTab}
            />
          )}
        </Tab.Pane>
      ),
    };
  }

  showLoader = (): React.ReactNode => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingApplication,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return (
      <>
        {this.state.loading && (
          <div
            style={{ backgroundColor: 'rgba(255,255,255,1)' }}
            id="main-loading-layer"
            className="ui inverted"
          >
            <div className="main-loading-layer">
              <Lottie options={defaultOptions} height={100} width={100} />
              {this.state.loadingText}
            </div>
          </div>
        )}
      </>
    );
  };

  getColorPercent = (): string => {
    const numberArray = [20, 40, 60, 80, 100];
    const currentPercent = this.getProgressBarPercent();
    if (this.state.boardingConfiguration) {
      let closestNumber = numberArray[0];
      let closestDifference = Math.abs(currentPercent - closestNumber);
      for (let i = 1; i < numberArray.length; i++) {
        const currentNumber = numberArray[i];
        const currentDifference = Math.abs(currentPercent - currentNumber);
        if (currentDifference < closestDifference) {
          closestNumber = currentNumber;
          closestDifference = currentDifference;
        }
      }
      const percentageColor = `boardingProgressBarBackgroundColor${closestNumber}`;
      return this.state.listColors[
        this.state.boardingConfiguration[percentageColor]
      ];
    }
    return currentPercent < 100 ? 'blue' : 'green';
  };

  renderBoarding(): React.ReactNode {
    const panes = [
      this.state.middlewareResult?.businessDetails ? this.businessTab() : {},
      this.state.middlewareResult?.owners ? this.ownerContactTab() : {},
      this.state.middlewareResult?.processing ? this.processingTab() : {},
      this.state.middlewareResult?.services ? this.servicesTab() : {},
      this.state.middlewareResult?.banking ? this.bankigTab() : {},
    ];
    const { services } = this.state.middlewareResult;
    let submitBtnText = 'E-SIGN & SUBMIT';
    if (
      (!services?.card?.acceptance ||
        !services?.card?.pdfTemplateId ||
        !services?.card?.pdfTemplateId?.value) &&
      (!services?.ach?.acceptance ||
        !services?.ach?.pdfTemplateId ||
        !services?.ach?.pdfTemplateId.value)
    ) {
      submitBtnText = 'SUBMIT';
    }
    return (
      <>
        {this.state.boardingConfiguration &&
          Object.entries(this.state.boardingConfiguration || []).length > 0 && (
            <style
              dangerouslySetInnerHTML={{
                __html: `
              .boarding-container{
                background: linear-gradient(125deg, ${
                  this.state.boardingConfiguration.boardingBackgroundColorFrom
                } 0%, ${
                  this.state.boardingConfiguration.boardingBackgroundColorTo
                } 100%);
                font-family: ${
                  this.state.boardingConfiguration.boardingFontFamily
                } !important;
                box-shadow: ${
                  this.state.boardingConfiguration.boardingBorderNone
                    ? ''
                    : 'none'
                } !important;
                font-size: ${
                  this.state.boardingConfiguration.boardingFontSize
                } !important;
                color: ${
                  this.state.boardingConfiguration.boardingFontColor
                } !important;
            }
            .pricing-container{
              background: linear-gradient(125deg, ${
                this.state.boardingConfiguration.boardingBackgroundColorFrom
              } 0%, ${
                  this.state.boardingConfiguration.boardingBackgroundColorTo
                } 100%) !important;
              font-family: ${
                this.state.boardingConfiguration.boardingFontFamily
              } !important;
              box-shadow: ${
                this.state.boardingConfiguration.boardingBorderNone
                  ? ''
                  : 'none'
              } !important;
              font-size: ${
                this.state.boardingConfiguration.boardingFontSize
              } !important;
              color: ${
                this.state.boardingConfiguration.boardingFontColor
              } !important;
            }
            .termscondition-container{
              background: linear-gradient(125deg, ${
                this.state.boardingConfiguration.boardingBackgroundColorFrom
              } 0%, ${
                  this.state.boardingConfiguration.boardingBackgroundColorTo
                } 100%) !important;
              font-family: ${
                this.state.boardingConfiguration.boardingFontFamily
              } !important;
              box-shadow: ${
                this.state.boardingConfiguration.boardingBorderNone
                  ? ''
                  : 'none'
              } !important;
              font-size: ${
                this.state.boardingConfiguration.boardingFontSize
              } !important;
              color: ${
                this.state.boardingConfiguration.boardingFontColor
              } !important;
            }
            .application-tabs .ui.container .ui.segment{
              background: linear-gradient(125deg, ${
                this.state.boardingConfiguration.boardingBackgroundColorFrom
              } 0%, ${
                  this.state.boardingConfiguration.boardingBackgroundColorTo
                } 100%);
              font-family: ${
                this.state.boardingConfiguration.boardingFontFamily
              } !important;
              box-shadow: ${
                this.state.boardingConfiguration.boardingBorderNone
                  ? ''
                  : 'none'
              } !important;
              font-size: ${
                this.state.boardingConfiguration.boardingFontSize
              } !important;
              color: ${
                this.state.boardingConfiguration.boardingFontColor
              } !important;
            }
            .boarding-container .tabs-config.tabs-color-text {
              color: ${
                this.state.boardingConfiguration.boardingTabsSuccessColor ||
                '#92d050'
              } !important;
            }
            .boarding-container .tabs-config {
                background-color: ${
                  this.state.boardingConfiguration
                    .boardingTabsBackgroundColorTransparent
                    ? 'transparent'
                    : this.state.boardingConfiguration
                        .boardingTabsBackgroundColor
                } !important;
                color: ${
                  this.state.boardingConfiguration.boardingTabsFontColor ||
                  '#92d050'
                } !important;
                font-family: ${
                  this.state.boardingConfiguration.boardingFontFamily
                } !important;
                border: solid ${
                  this.state.boardingConfiguration.boardingTabsBorderWidth
                } ${
                  this.state.boardingConfiguration.boardingTabsBorderColor
                } !important;
                font-size: ${
                  this.state.boardingConfiguration.boardingTabsFontSize
                } !important;
              }
            .boarding-container input.field__input, .application-tabs input.search{
              background-color: ${
                this.state.boardingConfiguration.boardingInputBackgroundColor
              } !important;
              color: ${
                this.state.boardingConfiguration.boardingInputFontColor
              } !important;
              border-radius: ${
                this.state.boardingConfiguration.boardingInputsBorderRadius
              } !important;
              font-size: ${
                this.state.boardingConfiguration.boardingInputFontSize
              } !important;
              border: solid ${
                this.state.boardingConfiguration.boardingInputBorderSize
              } ${
                  this.state.boardingConfiguration.boardingInputBorderColor
                } !important;
            }
            .ui.fluid.dropdown{
              background-color: ${
                this.state.boardingConfiguration.boardingInputBackgroundColor
              } !important;
              color: ${
                this.state.boardingConfiguration.boardingInputFontColor
              } !important;
              border-radius: ${
                this.state.boardingConfiguration.boardingInputsBorderRadius
              } !important;
              font-size: ${
                this.state.boardingConfiguration.boardingInputFontSize
              } !important;
              border: solid ${
                this.state.boardingConfiguration.boardingInputBorderSize
              } ${
                  this.state.boardingConfiguration.boardingInputBorderColor
                } !important;
            }
            .boarding-container input.field__input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: ${
                this.state.boardingConfiguration.boardingInputsPlaceholderColor
              } !important;
              opacity: 1 !important;; /* Firefox */
            }
            .boarding-container input.field__input:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: ${
                this.state.boardingConfiguration.boardingInputsPlaceholderColor
              } !important;
            }
            .boarding-container input.field__input::-ms-input-placeholder { /* Microsoft Edge */
              color: ${
                this.state.boardingConfiguration.boardingInputsPlaceholderColor
              } !important;
            }
            .boarding-container label{
              color: ${
                this.state.boardingConfiguration.boardingInputsPlaceholderColor
              } !important;
              font-size: ${
                this.state.boardingConfiguration.boardingInputsLabelsFontSize
              } !important;
            }
            .boarding-container .ui.dropdown:not(.button)>.default.text { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: ${
                this.state.boardingConfiguration.boardingInputsPlaceholderColor
              } !important;
              opacity: 1 !important;; /* Firefox */
            }
            .delete-button-owner{
                color: ${
                  this.state.boardingConfiguration.boardingOwnerDeleteFontColor
                } !important;
                font-size: ${
                  this.state.boardingConfiguration.boardingOwnerDeleteFontSize
                } !important;
                font-family: ${
                  this.state.boardingConfiguration.boardingOwnerAddFontFamily
                } !important;
                border-radius: ${
                  this.state.boardingConfiguration
                    .boardingOwnerDeleteBorderRadius
                } !important;
                background: linear-gradient(125deg, ${
                  this.state.boardingConfiguration
                    .boardingOwnerDeleteBackgroundColorFrom
                } 0%, ${
                  this.state.boardingConfiguration
                    .boardingOwnerDeleteBackgroundColorTo
                } 100%) !important;
            }
            .add-button-owner{
                color: ${
                  this.state.boardingConfiguration.boardingOwnerAddFontColor
                } !important;
                font-size: ${
                  this.state.boardingConfiguration.boardingOwnerAddFontSize
                } !important;
                font-family: ${
                  this.state.boardingConfiguration.boardingOwnerAddFontFamily
                } !important;
                border-radius: ${
                  this.state.boardingConfiguration.boardingOwnerAddBorderRadius
                } !important;
                background: linear-gradient(125deg, ${
                  this.state.boardingConfiguration
                    .boardingOwnerAddBackgroundColorFrom
                } 0%, ${
                  this.state.boardingConfiguration
                    .boardingOwnerAddBackgroundColorTo
                } 100%);
            }
            .delete-button-contact{
              color: ${
                this.state.boardingConfiguration.boardingContactDeleteFontColor
              } !important;
              font-size: ${
                this.state.boardingConfiguration.boardingContactDeleteFontSize
              } !important;
              font-family: ${
                this.state.boardingConfiguration.boardingContactAddFontFamily
              } !important;
              border-radius: ${
                this.state.boardingConfiguration
                  .boardingContactDeleteBorderRadius
              } !important;
              background: linear-gradient(125deg, ${
                this.state.boardingConfiguration
                  .boardingContactDeleteBackgroundColorFrom
              } 0%, ${
                  this.state.boardingConfiguration
                    .boardingContactDeleteBackgroundColorTo
                } 100%) !important;
            }
            .add-button-contact{
              color: ${
                this.state.boardingConfiguration.boardingContactAddFontColor
              } !important;
              font-size: ${
                this.state.boardingConfiguration.boardingContactAddFontSize
              } !important;
              font-family: ${
                this.state.boardingConfiguration.boardingContactAddFontFamily
              } !important;
              border-radius: ${
                this.state.boardingConfiguration.boardingContactAddBorderRadius
              } !important;
              background: linear-gradient(125deg, ${
                this.state.boardingConfiguration
                  .boardingContactAddBackgroundColorFrom
              } 0%, ${
                  this.state.boardingConfiguration
                    .boardingContactAddBackgroundColorTo
                } 100%);
            }
            .footer-button-color{
                border: solid ${
                  this.state.boardingConfiguration
                    .boardingFooterButtonsBorderSize
                } #eeeeee !important;
                font-family: ${
                  this.state.boardingConfiguration
                    .boardingFooterButtonsFontFamily
                } !important;
                color: ${
                  this.state.boardingConfiguration
                    .boardingFooterButtonsFontColor
                } !important;
                font-size: ${
                  this.state.boardingConfiguration.boardingFooterButtonsFontSize
                } !important;
                border-radius: ${
                  this.state.boardingConfiguration
                    .boardingFooterButtonsBorderRadius
                } !important;
                background: linear-gradient(125deg, ${
                  this.state.boardingConfiguration
                    .boardingFooterButtonsBackgroundColorFrom
                } 0%, ${
                  this.state.boardingConfiguration
                    .boardingFooterButtonsBackgroundColorTo
                } 100%) !important;
            }
            .ui.blue.progress .bar {
              background-color: #ff0000 !important;
            }w
                ${this.state.boardingConfiguration.boardingCssCode}
                        `,
              }}
            />
          )}
        {this.getEmailModal()}
        {/* {this.getFinishedModal()} */}
        {this.getSaveToCompleteLaterModal()}
        {this.getfileExceededSizeModal()}
        {this.showLoader()}

        {this.state.login && !this.state.noLoginEdit ? (
          <LoginBoarding
            error={this.state.error}
            getApplicationFromApi={this.getApplicationFromApi}
          />
        ) : this.state.noLoginEdit && this.state.error ? (
          <ErrorPopUp
            error={this.state.error}
            reloadError={this.state.reloadError}
          />
        ) : this.state.endLinkModalIsOpen ? (
          this.getFinishedModal()
        ) : (
          <div
            style={this.state.loading ? { display: 'none' } : {}}
            className={
              this.props?.settings?.boardingContainer || 'boarding-container'
            }
            data-testid="boarding-container"
          >
            <div className="main-branding">
              <Image
                src={`${
                  this.state.logo ? this.state.logo : payabli
                }?nocache=${new Date().getTime().toString()}`}
                size="medium"
                centered
              />
            </div>
            <div className="application-tabs">
              <Container>
                <div style={{ padding: '1em 1em' }}>
                  <Progress
                    className="bar-color1"
                    autoSuccess
                    size="medium"
                    progress
                    percent={this.getProgressBarPercent()}
                    color={(this.getColorPercent() as SemanticCOLORS) ?? 'blue'}
                  />
                </div>
                <Tab
                  onTabChange={this.handleTabChange}
                  key="tabs-boardinglinks"
                  activeIndex={this.state.currentPos}
                  menu={{
                    borderless: true,
                    attached: false,
                    tabular: false,
                    secondary: true,
                    text: true,
                    className: 'tab-menu',
                    style: { justifyContent: 'center' },
                  }}
                  panes={panes}
                />
              </Container>
            </div>
            <div className="card-footer">
              <Grid columns={3}>
                <Grid.Row>
                  <Grid.Column textAlign="left">
                    <button
                      className="footer-button-color btn"
                      onClick={(): void =>
                        this.gotoPrevTab(this.state.currentPos - 1)
                      }
                    >
                      <BiChevronLeft /> Previous
                    </button>
                  </Grid.Column>
                  <Grid.Column textAlign="center">
                    {this.state.resumable && (
                      <button
                        className="btn footer-button-color"
                        onClick={this.saveToCompleteLater}
                      >
                        Save for Later
                      </button>
                    )}
                    {!this.state.resumable && (
                      <>
                        <Popup
                          content={
                            <Message warning>
                              <MessageHeader style={{ textAlign: 'center' }}>
                                Can't Save
                              </MessageHeader>
                              <p>
                                This application isn't resumable, and we can't
                                save your progress. Complete the application and
                                try again.
                              </p>
                            </Message>
                          }
                          size="tiny"
                          wide="very"
                          positionFixed
                          position="bottom center"
                          trigger={
                            <Icon
                              name="info circle"
                              color="yellow"
                              size="large"
                            />
                          }
                        />
                      </>
                    )}
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                    {this.state.currentPos < 4 ? (
                      <button
                        className="btn footer-button-color"
                        onClick={(): void =>
                          this.gotoNextTab(this.state.currentPos + 1)
                        }
                      >
                        Next <BiChevronRight />
                      </button>
                    ) : (
                      <>
                        <button
                          className="btn footer-button-color"
                          onClick={(): void =>
                            this.gotoNextTabAndSubmit(this.state.currentPos + 1)
                          }
                        >
                          {submitBtnText} <BiChevronRight />
                        </button>
                      </>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <pre style={{ display: 'none' }}>
              {JSON.stringify(this.props.boarding, null, 2)}
            </pre>
            <ToastContainer transition={Bounce} />
          </div>
        )}
      </>
    );
  }

  /* Rendering the OnBoardingView react component. */
  render(): React.ReactNode {
    return this.renderBoarding();
  }
}
export default inject('boarding')(observer(BoardingLinkView));
