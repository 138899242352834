import * as Sentry from '@sentry/react';
import type { AxiosResponse } from 'axios';
import axios, { AxiosError } from 'axios';

import { pdfHtml } from '../utils/PdfTemplate';

const MAX_RETRIES: number =
  parseInt(process.env.REACT_APP_API_MAX_RETRIES_REQUEST as string, 10) || 1;

interface IBoardingService {
  update(token: string, values: string, id: string): Promise<any>;
  create(token: string, values: string): Promise<any>;
}

class BoardingService implements IBoardingService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private async retryRequest(
    requestFn: () => Promise<AxiosResponse<any>>
  ): Promise<any> {
    for (let attempt = 1; attempt <= MAX_RETRIES; attempt++) {
      try {
        // eslint-disable-next-line no-await-in-loop
        return await requestFn();
      } catch (error: any) {
        if (!this.shouldRetry(error)) {
          Sentry.captureEvent(error);
          throw error;
        }
        if (attempt === MAX_RETRIES) {
          if (
            (error instanceof AxiosError &&
              error?.message === 'Network Error') ||
            error?.name === 'AxiosError'
          ) {
            Sentry.captureEvent(error);
            throw new Error(
              error?.response?.data?.responseText ||
                `We are having trouble connecting to the API.`
            );
          }
          Sentry.captureEvent(error);
          throw new Error(
            `Failed after ${MAX_RETRIES} attempts: ${
              error.message || 'Unknown error'
            }`
          );
        }
      }
    }
  }

  private shouldRetry(error: any): boolean {
    return (
      (error.response &&
        error.response.status >= 400 &&
        error.response.status < 500) ||
      error instanceof AxiosError
    );
  }

  public getAuthHeaders(token: string): any {
    return { headers: { requestToken: token } };
  }

  public async default_get(token: string, appId: string): Promise<any> {
    return this.retryRequest(() =>
      axios.get(
        `${this.baseUrl}Boarding/link/${appId}?reCompile=true`,
        this.getAuthHeaders(token)
      )
    );
  }

  public async read(
    token: string,
    email: string,
    referenceId: string,
    id: string
  ): Promise<any> {
    return this.retryRequest(() => {
      return axios.post(
        `${this.baseUrl}Boarding/read/${id}`,
        { email, referenceId },
        this.getAuthHeaders(token)
      );
    });
  }

  public async updateApplicationStatus(
    appId: number,
    status: string
  ): Promise<any> {
    return this.retryRequest(() =>
      axios.get(
        `${this.baseUrl}Boarding/appsts/${appId}/${status}`,
        this.getAuthHeaders(process.env.REACT_APP_TOKEN || '')
      )
    );
  }

  public async generatePDF(values: any, services: any): Promise<any> {
    if (!values) {
      throw new Error('Values parameter is missing');
    }

    values.services = services;

    return this.retryRequest(() =>
      axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/document/signEmbedded`,
        values,
        {
          headers: {
            'x-api-key': process.env.REACT_APP_XAPI_KEY as string,
            Authorization: `Basic ${
              process.env.REACT_APP_BACKEND_AUTH_TOKEN as string
            }`,
          },
        }
      )
    );
  }

  public async update(
    token: string,
    values: string,
    id: string,
    validateBeforeSave?: boolean
  ): Promise<any> {
    const searchParams = validateBeforeSave ? '?validateBeforeSave=true' : '';
    return this.retryRequest(() =>
      axios.put(
        `${this.baseUrl}Boarding/app/${id}${searchParams}`,
        values,
        this.getAuthHeaders(token)
      )
    );
  }

  public async create(
    token: string,
    values: string,
    validateBeforeSave?: boolean
  ): Promise<any> {
    const searchParams = validateBeforeSave ? '?validateBeforeSave=true' : '';
    return this.retryRequest(() =>
      axios.post(
        `${this.baseUrl}Boarding/app${searchParams}`,
        values,
        this.getAuthHeaders(token)
      )
    );
  }

  public async delete(token: string, appId: string): Promise<any> {
    return this.retryRequest(() =>
      axios.delete(
        `${this.baseUrl}Boarding/app/${appId}`,
        this.getAuthHeaders(token)
      )
    );
  }

  public async exportPdf(): Promise<any> {
    const buffer = Buffer.from(pdfHtml);
    const body = {
      resultType: '0',
      'htmlText ': buffer.toString('base64'),
    };

    return axios.post(`${this.baseUrl}Tools/getPdfHtml`, body, {
      headers: {
        'content-type': 'application/*+json',
        requestToken: process.env.REACT_APP_TOKEN || '',
      },
    });
  }
}

export default BoardingService;
